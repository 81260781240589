<template>
  <v-list-item class="mb-0 justify-space-between text-center">
    <v-list-item-icon v-if="mini" class="my-2 align-self-center ma-0">
      <v-icon color="primary" large>mdi-home</v-icon>
    </v-list-item-icon>
    <v-list-item-content v-if="!mini">
      <v-list-item-title class="text-h3">
        <strong class="mr-2 font-weight-black">{{
          $t("header.title.prefix")
        }}</strong>
        <span class="primary--text">{{ $t("header.title.postfix") }}</span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultDrawerHeader",

  computed: {
    version: get("app/version"),
    mini: sync("app/mini")
  }
};
</script>
